<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <!--begin::Card-->
          <div class="card">
            <div class="card-body p-10 p-lg-15">
              <h1 class="anchor fw-bold mb-5" id="overview">Result Settings</h1>
              <div class="py-5">
                Catalog result settings are set through the REST API. List of uploaded
                <router-link
                    to="/result-settings/catalog"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <span class="menu-text">Result Settings Front API.</span>
                  </a>
                </router-link>

              </div>
            </div>
          </div>
          <!--end::Card-->
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b">
            <div class="card-header">

              <div class="card-title">
                <h3 class="card-label">
                  Product Data Retrieval
                  <small>zde nějaký super podnadpis</small>
                </h3>
              </div>
              <div class="card-toolbar">
                <a
                    href="#"
                    class="btn btn-info"
                    ref="submit"
                    @click.prevent="saveCatalog"
                >Save</a>
              </div>
            </div>
            <v-progress-linear
                v-if="this.loading.catalog.data"
                color="blue"
                indeterminate
            ></v-progress-linear>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h3>Configure the data display for the frontend.</h3>
                  <div role="tablist">
                    <b-card
                        no-body
                        class="mb-1"
                        v-for="collection in catalog.collections"
                        :key="collection.id"
                    >
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <div
                            class="d-flex align-items-center mb-9 bg-light rounded p-5"
                            v-b-toggle="'accordion-search-' + collection.id"
                            variant="info"
                            @click.prevent=""
                        >
                          <!--begin::Icon-->
                          <span class="svg-icon svg-icon-warning mr-5">
                          <span class="svg-icon svg-icon-lg"> </span>
                        </span>
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                            >{{ collection.name }}</a
                            >
                          </div>
                          <!--end::Title-->
                        </div>
                      </b-card-header>
                      <b-collapse
                          v-bind:id="'accordion-search-' + collection.id"
                          visible
                          accordion="accordion-search"
                          role="tabpanel"
                      >
                        <b-card-body>
                          <section v-if="collection.searchable">
                            <v-text-field
                                v-model="collection.count"
                                :rules="[rules.isNumeric]"
                                counter="25"
                                label="Count of items"
                                outlined
                            ></v-text-field>

                            <v-container v-if="getSortable(collection).length > 0">
                              <v-row>
                                <v-col cols="8">
                                <v-select
                                  v-model="collection.default_sort"
                                  :items="getSortable(collection)"
                                  label="Default sortable"
                              >
                              </v-select>
                                </v-col>
                                <v-col cols="4">
                              <v-select
                                  v-model="collection.default_sort_order_by"
                                  :items="orderByOptions"
                                  label="Default sortable"
                              >
                              </v-select>
                                </v-col>
                              </v-row>
                            </v-container>
                            <v-container v-if="collection.fields.length > 0">
                              <hr/>
                              <b-card-sub-title>Fields</b-card-sub-title>
                              <v-container
                                  v-for="field in collection.fields"
                                  :key="field.id"
                              >
                                <v-row v-if="field.can_be_sort"
                                >
                                <strong>{{ field.name }}</strong>
                                <v-col cols="12">


                                  <v-switch
                                      v-if="field.can_be_sort"
                                      v-model="field.sort_use"
                                      @change="resetSortValues(field)"
                                      label="Sort"
                                  ></v-switch>
                                  <v-text-field
                                      v-if="field.sort_use"
                                      v-model="field.sort_asc"
                                      type="text"
                                      outlined
                                      label="Sort ASC label"
                                  >
                                  </v-text-field>
                                  <v-text-field
                                      v-if="field.sort_use"
                                      v-model="field.sort_desc"
                                      type="text"
                                      outlined
                                      label="Sort DESC label"
                                  >
                                  </v-text-field>
                                </v-col>
                                </v-row>
                              </v-container>
                            </v-container>
                          </section>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
                <div class="col-md-6">
                  <h3>Test how the response appears on the frontend</h3>
                  <p>You just need to type, and the data is generated in real time</p>

                  <div class="row mt-5">
                    <div class="col-md-4">
                      <v-text-field
                          label="dimension"
                          v-on:keyup="methodCatalog"
                          v-model="queryCatalog.dimension"
                          hint="např. d[category]=5"
                          outlined
                      ></v-text-field>
                    </div>
                    <div class="col-md-4">
                      <v-select
                          v-model="queryCatalog.currency"
                          :items="currenciesOptions"
                          filled
                          label="Currency"
                          v-on:change="methodCatalog()"
                          dense>

                      </v-select>
                    </div>
                    <div class="col-md-4">

                      <v-select
                          v-model="queryCatalog.localization"
                          :items="languagesOptions"
                          filled
                          label="Language"
                          v-on:change="methodCatalog()"
                          dense>
                      </v-select>
                    </div>
                  </div>
                  <v-progress-linear
                      v-if="this.loading.catalog.query"
                      color="blue"
                      indeterminate
                  ></v-progress-linear>
                  <div class="bg-dark text-white p-10">
                    <vue-json-pretty
                        :deep="2"
                        :collapsedNodeLength="2"
                        :data="catalog.results"
                        @click="handleClick"
                    ></vue-json-pretty>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card card-custom gutter-b">
            <div class="card-header">

              <div class="card-title">
                <h3 class="card-label">
                  Autocomplete Data Retrieval
                  <small>Enhancing Search Functionality</small>
                </h3>
              </div>
              <div class="card-toolbar">
                <a
                    href="#"
                    class="btn btn-info"
                    ref="submit"
                    @click.prevent="saveAutocomplete"
                >Save</a>
              </div>
            </div>
            <v-progress-linear
                v-if="this.loading.autocomplete.data"
                color="blue"
                indeterminate
            ></v-progress-linear>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h3>Configure the data display for the frontend.</h3>
                  <div class="form-group">
                    <v-container style="background: #eee">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                              v-model="autocomplete.phrase_label"
                              type="text"
                              outlined
                              label="Label"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                              v-model="autocomplete.phrase"
                              :items="autocomplete.phrases"
                              filled
                              label="Phrase count"
                              dense
                          ></v-select>
                        </v-col>
                      </v-row>


                      <v-row v-for="language in autocomplete.phrase_labels_i18n" :key="language.language">
                        <v-col cols="12">
                          <v-text-field v-model="language.value" label="label">
                            <template v-slot:prepend>
                              {{ language.language }}
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                  <div role="tablist">
                    <b-card
                        no-body
                        class="mb-1"
                        v-for="collection in autocomplete.collections"
                        :key="collection.id"
                    >
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <div
                            class="d-flex align-items-center mb-9 bg-light rounded p-5"
                            v-b-toggle="'accordion-' + collection.id"
                            variant="info"
                            @click.prevent=""
                        >
                          <!--begin::Icon-->
                          <span class="svg-icon svg-icon-warning mr-5">
                          <span class="svg-icon svg-icon-lg"> </span>
                        </span>
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                            >{{ collection.name }}</a
                            >
                          </div>
                          <!--end::Title-->
                        </div>
                      </b-card-header>
                      <b-collapse
                          v-bind:id="'accordion-' + collection.id"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-sub-title>Collection Search</b-card-sub-title>
                          <v-switch
                              v-model="collection.searchable"
                              label="Search in this collection"
                          ></v-switch>

                          <section v-if="collection.searchable">
                            <v-select
                                label="Layout Column"
                                ref="c_layout_column_id"
                                item-text="name"
                                item-value="id"
                                :rules="[v => !!v || 'Item is required']"
                                v-model="collection.layout_column_id"
                                :items="layout_columns"
                                required
                            ></v-select>
                            <v-text-field
                                v-model="collection.count"
                                :rules="[rules.isNumeric]"
                                counter="25"
                                label="Count of items"
                                outlined
                            ></v-text-field>

                            <v-text-field
                                v-model="collection.ordinal_number"
                                :rules="[rules.isNumeric]"
                                counter="3"
                                label="Ordinal Number"
                                outlined
                            ></v-text-field>


                            <v-text-field
                                v-model="collection.label"
                                type="text"
                                outlined
                                label="Label"
                            ></v-text-field>

                            <v-container>
                                <v-row v-for="language in collection.labels_i18n" :key="language.language">
                                  <v-col cols="12">
                                    <v-text-field v-model="language.value" label="label">
                                      <template v-slot:prepend>
                                        {{ language.language }}
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                            </v-container>


                            <v-container v-if="collection.fields.length > 0">
                              <hr/>
                              <b-card-sub-title>Fields</b-card-sub-title>
                              <v-row

                                  v-for="field in collection.fields"
                                  :key="field.id"
                              >
                                <strong>{{ field.name }}</strong>
                                <v-col cols="12">
                                  <v-switch
                                      v-model="field.response"
                                      label="Response"
                                      v-if="currentProject.source !== 'mastershop-api'"
                                  ></v-switch>
                                  <v-switch
                                      v-if="field.response"
                                      v-model="field.array"
                                      label="Return as array"
                                  >
                                  </v-switch>
                                  <v-text-field
                                      v-if="field.response && field.can_be_aggregate"
                                      v-model="field.cutter"
                                      type="number"
                                      outlined
                                      label="Limit text"
                                  >
                                  </v-text-field>
                                  <v-switch
                                      v-if="field.can_be_aggregate"
                                      v-model="field.filter"
                                      label="Aggregate"
                                  ></v-switch>

                                  <v-select
                                      v-if="field.filter"
                                      label="Layout Column"
                                      ref="f_layout_column_id"
                                      item-text="name"
                                      item-value="id"
                                      :rules="[v => !!v || 'Item is required']"
                                      v-model="field.layout_column_id"
                                      :items="layout_columns"
                                      required
                                  ></v-select>

                                  <v-select
                                      v-if="field.filter"
                                      label="Aggregation collection linked"
                                      item-text="name"
                                      item-value="id"
                                      v-model="field.aggregation_collection_linked"
                                      :items="collections"
                                  ></v-select>

                                  <v-text-field
                                      v-if="field.filter && !field.aggregation_collection_linked"
                                      v-model="field.label"
                                      type="text"
                                      outlined
                                      label="Label"
                                  >
                                  </v-text-field>

                                  <v-container v-if="field.filter  && !field.aggregation_collection_linked">
                                    <v-row v-for="language in field.labels_i18n" :key="language.language">
                                      <v-col cols="12">
                                        <v-text-field v-model="language.value" label="label">
                                          <template v-slot:prepend>
                                            {{ language.language }}
                                          </template>
                                        </v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-container>



                                  <v-text-field
                                      v-if="field.filter && !field.aggregation_collection_linked"
                                      v-model="field.ordinal_number"
                                      :rules="[rules.isNumeric]"
                                      counter="3"
                                      label="Ordinal Number"
                                      outlined
                                  ></v-text-field>
                                  <v-text-field
                                      v-if="field.filter && !field.aggregation_collection_linked"
                                      v-model="field.aggregate_count"
                                      type="number"
                                      outlined
                                      label="Aggregate Count"
                                  >
                                    <v-switch
                                        v-if="field.can_be_aggregate"
                                        v-model="field.highlight"
                                        label="Highlight"
                                    ></v-switch>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </section>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
                <div class="col-md-6">
                  <h3>Test how the response appears on the frontend</h3>
                  <p>You just need to type, and the data is generated in real time</p>

                  <div class="row mt-5">
                    <div class="col-md-4">
                      <v-text-field
                          label="Query test"
                          v-on:keyup="methodAutocompleteSearch"
                          v-model="queryAutocomplete.query"
                          outlined
                      ></v-text-field>
                    </div>
                    <div class="col-md-4">
                      <v-select
                          v-model="queryAutocomplete.currency"
                          :items="currenciesOptions"
                          filled
                          label="Currency"
                          v-on:change="methodAutocompleteSearch()"
                          dense>

                      </v-select>
                    </div>
                    <div class="col-md-4">

                      <v-select
                          v-model="queryAutocomplete.localization"
                          :items="languagesOptions"
                          filled
                          label="Language"
                          v-on:change="methodAutocompleteSearch()"
                          dense>
                      </v-select>
                    </div>
                  </div>
                  <v-progress-linear
                      v-if="this.loading.autocomplete.query"
                      color="blue"
                      indeterminate
                  ></v-progress-linear>
                  <div class="bg-dark text-white p-10">
                    <vue-json-pretty
                        :deep="2"
                        :collapsedNodeLength="2"
                        :data="autocomplete.results"
                        @click="handleClick"
                    ></vue-json-pretty>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card card-custom gutter-b">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">
                  SERP
                  <small>Retrieving Data with Pagination and Filters</small>
                </h3>
              </div>
              <div class="card-toolbar">
                <a
                    href="#"
                    class="btn btn-info"
                    ref="submit"
                    @click.prevent="saveSearch"
                >Save</a
                >
              </div>
            </div>
            <v-progress-linear
                v-show="this.loading.search.data"
                color="blue"
                indeterminate
            ></v-progress-linear>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h3>Configure the data display for the frontend.</h3>
                  <div role="tablist">
                    <b-card
                        no-body
                        class="mb-1"
                        v-for="collection in search.collections"
                        :key="collection.id"
                    >
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <div
                            class="d-flex align-items-center mb-9 bg-light rounded p-5"
                            v-b-toggle="'accordion-search-' + collection.id"
                            variant="info"
                            @click.prevent=""
                        >
                          <!--begin::Icon-->
                          <span class="svg-icon svg-icon-warning mr-5">
                          <span class="svg-icon svg-icon-lg"> </span>
                        </span>
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                            >{{ collection.name }}</a
                            >
                          </div>
                          <!--end::Title-->
                        </div>
                      </b-card-header>
                      <b-collapse
                          v-bind:id="'accordion-search-' + collection.id"
                          visible
                          accordion="accordion-search"
                          role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-sub-title>Collection Search</b-card-sub-title>
                          <v-switch
                              v-model="collection.searchable"
                              label="Search in this collection"
                          ></v-switch>

                          <section v-if="collection.searchable">
                            <v-text-field
                                v-model="collection.count"
                                :rules="[rules.isNumeric]"
                                counter="25"
                                label="Count of items"
                                outlined
                            ></v-text-field>
                            <v-text-field
                                v-model="collection.ordinal_number"
                                :rules="[rules.isNumeric]"
                                counter="3"
                                label="Ordinal Number"
                                outlined
                            ></v-text-field>
                            <v-select
                                label="Set as Additional Item of"
                                ref="f_parent_id"
                                item-text="name"
                                item-value="id"
                                v-model="collection.parent_id"
                                :items="collections"
                                required
                            ></v-select>

                            <v-container v-if="collection.searchable">

                              <v-text-field
                                  v-if="collection.searchable"
                                  v-model="collection.label"
                                  type="text"
                                  outlined
                                  label="Label"
                              >
                              </v-text-field>

                              <v-row v-for="language in collection.labels_i18n" :key="language.language">
                                <v-col cols="12">
                                  <v-text-field v-model="language.value" label="label">
                                    <template v-slot:prepend>
                                      {{ language.language }}
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>

                            <v-container v-if="collection.fields.length > 0">
                              <hr/>
                              <b-card-sub-title>Fields</b-card-sub-title>
                              <v-container
                                  v-for="field in collection.fields"
                                  :key="field.id"
                              >
                                <v-row v-if="field.can_be_sort"
                                >
                                  <strong>{{ field.name }}</strong>
                                  <v-col cols="12">
                                    <v-switch
                                        v-if="currentProject.source !== 'mastershop-api' "
                                        v-model="field.response"
                                        label="Response"
                                    ></v-switch>
                                    <v-switch
                                        v-if="field.response"
                                        v-model="field.array"
                                        label="Return as array"
                                    >
                                    </v-switch>
                                    <v-switch
                                        v-if="field.can_be_filter"
                                        v-model="field.filter"
                                        label="Filter"
                                    ></v-switch>

                                    <v-container v-if="field.filter">
                                      <v-text-field
                                          v-if="field.filter"
                                          v-model="field.label"
                                          type="text"
                                          outlined
                                          label="Label"
                                      >
                                      </v-text-field>
                                      <v-row v-for="language in field.labels_i18n" :key="language.language">
                                        <v-col cols="12">
                                          <v-text-field v-model="language.value" label="label">
                                            <template v-slot:prepend>
                                              {{ language.language }}
                                            </template>
                                          </v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                    <v-text-field
                                        v-if="field.filter"
                                        v-model="field.ordinal_number"
                                        :rules="[rules.isNumeric]"
                                        counter="3"
                                        label="Ordinal Number"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        v-if="field.filter"
                                        v-model="field.suffix"
                                        type="text"
                                        outlined
                                        label="Suffix"
                                    >
                                    </v-text-field>





                                    <v-switch
                                        v-if="field.can_be_sort"
                                        v-model="field.sort_use"
                                        label="Sort"
                                    ></v-switch>
                                    <v-text-field
                                        v-if="field.sort_use"
                                        v-model="field.sort_asc"
                                        type="text"
                                        outlined
                                        label="Sort ASC label"
                                    >
                                    </v-text-field>
                                    <v-text-field
                                        v-if="field.sort_use"
                                        v-model="field.sort_desc"
                                        type="text"
                                        outlined
                                        label="Sort DESC label"
                                    >
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-container>                          </section>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
                <div class="col-md-6">
                  <h3>Test how the response appears on the frontend</h3>
                  <p>You just need to type, and the data is generated in real time</p>

                  <div class="row mt-5">
                    <div class="col-md-3">
                      <v-text-field
                          label="Query test"
                          v-on:keyup="methodSerp"
                          v-model="querySerp.query"
                          outlined
                      ></v-text-field>
                    </div>
                    <div class="col-md-3">
                      <v-select
                          v-model="querySerp.currency"
                          :items="currenciesOptions"
                          filled
                          label="Currency"
                          v-on:change="methodSerp()"
                          dense>

                      </v-select>
                    </div>
                    <div class="col-md-3">

                      <v-select
                          v-model="querySerp.localization"
                          :items="languagesOptions"
                          filled
                          label="Language"
                          v-on:change="methodSerp()"
                          dense>
                      </v-select>
                    </div>
                    <div class="col-md-3">

                      <v-select
                          v-model="querySerp.collection"
                          :items="collections"
                          item-text="name"
                          item-value="name"
                          filled
                          label="Collection"
                          v-on:change="methodSerp()"
                          dense>
                      </v-select>
                    </div>
                  </div>
                  <v-progress-linear
                      v-show="this.loading.search.query"
                      color="blue"
                      indeterminate
                  ></v-progress-linear>
                  <div class="bg-dark text-white p-10">
                    <vue-json-pretty
                        :deep="2"
                        :collapsedNodeLength="2"
                        :data="search.results"
                        @click="handleClick"
                    ></vue-json-pretty>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>

import VueJsonPretty from "vue-json-pretty";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import "vue-json-pretty/lib/styles.css";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapState} from "vuex";
import {
  GET_AUTOCOMPLETE,
  GET_AUTOCOMPLETE_QUERY, GET_CATALOG, GET_CATALOG_QUERY,
  GET_LIST_COLLECTIONS,
  GET_SEARCH, GET_SERP_QUERY,
  PULL_LAYOUT_COLUMNS,
  PUT_AUTOCOMPLETE, PUT_CATALOG,
  PUT_SEARCH
} from "@/core/services/store/result_settings.module";
import {RELOAD_MESSAGE} from "@/core/services/store/message.module";

export default {
  components: {VueJsonPretty},
  data() {
    return {
      selectLocalization: "cs",
      locales: [],
      valid: true,
      phrases: [
        {text: "Off", value: 0},
        {text: "1", value: 1},
        {text: "2", value: 2},
        {
          text: "3",
          value: 3
        },
        {text: "4", value: 4},
        {text: "5", value: 5}
      ],
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        url: value => {
          const pattern = /^(https?:\/\/)+[\w\\-~]+(\.[\w\\-~]+)+(\/[\w\-~]*)*(#[\w\\-]*)?(\\?.*)?$/;
          return pattern.test(value) || "Invalid website url.";
        },
        isNumeric: value => {
          return !isNaN(parseFloat(value)) && isFinite(value);
        }
      },
      filterFields: "",
      filterBuffer: "",
      searchBuffer: "",
      searchString: "",
      queryAutocomplete: {
        query: "test",
        currency: "CZK",
        localization: "cs"
      },
      querySerp: {
        query: "test",
        currency: "CZK",
        localization: "cs",
        collection: "products"
      },
      queryCatalog: {
        dimension: "d[category]=5",
        currency: "CZK",
        localization: "cs"
      },
    };
  },
  methods: {
    resetSortValues(field) {
      // Když se změní hodnota sort_use, resetujeme sort_asc a sort_desc
      field.sort_asc = "";
      field.sort_desc = "";
    },
    getSortable(collection){
      return collection.fields.filter(field => field.sort_desc || field.sort_asc)
          .map(field => ({
            value: field.id, // hodnota pro v-select
            text: field.sort_desc + " | " + field.sort_asc, // zobrazovaný text
            sort_desc: field.sort_desc,
            sort_asc: field.sort_asc
          }));
    },
    addLocale() {
      this.locales.push({
        key: this.selectLocalization,
        label: ""
      });
    },
    removeLocale(remIndex) {
      this.locales.forEach(function (item, index, object) {
        if (item.key === remIndex) {
          object.splice(index, 1);
        }
      });
    },
    methodAutocompleteSearch() {
      this.loading.autocomplete.query = true;
      this.$store.dispatch(GET_AUTOCOMPLETE_QUERY, "?q=" + this.queryAutocomplete.query + "&cc=" + this.queryAutocomplete.currency + "&lang=" + this.queryAutocomplete.localization);
    },
    methodSerp() {
      this.loading.search.query = true;
      this.$store.dispatch(GET_SERP_QUERY, "?q=" + this.querySerp.query + "&cc=" + this.querySerp.currency + "&lang=" + this.querySerp.localization + "&c=" + this.querySerp.collection);
    },
    methodCatalog() {
      this.loading.catalog.query = true;
      this.$store.dispatch(GET_CATALOG_QUERY, "?" + this.queryCatalog.dimension + "&cc=" + this.queryCatalog.currency + "&lang=" + this.queryCatalog.localization);
    },
    reloadData() {

      this.$store.dispatch(RELOAD_MESSAGE);
      this.$store.dispatch(GET_AUTOCOMPLETE);
      this.$store.dispatch(GET_AUTOCOMPLETE_QUERY);
      this.$store.dispatch(GET_SEARCH);
      this.$store.dispatch(GET_CATALOG);
      this.$store.dispatch(PULL_LAYOUT_COLUMNS);
      this.$store.dispatch(GET_LIST_COLLECTIONS);
    },
    saveAutocomplete() {
      this.loading.autocomplete.data = true;

      this.$store
          .dispatch(PUT_AUTOCOMPLETE, {
            data: this.autocomplete,
            query: this.queryString
          })
          .then(() => {
            this.$store.dispatch(GET_AUTOCOMPLETE_QUERY, "?q=" + this.queryAutocomplete.query + "&cc=" + this.queryAutocomplete.currency + "&lang=" + this.queryAutocomplete.localization);
          });
    },
    saveCatalog() {
      this.loading.catalog.data = true;

      this.$store
          .dispatch(PUT_CATALOG, {
            data: this.catalog
          })
          .then(() => {
            this.$store.dispatch(GET_CATALOG_QUERY, "?" + this.queryCatalog.dimension + "&cc=" + this.queryCatalog.currency + "&lang=" + this.queryCatalog.localization);
          });
    },
    saveSearch() {
      this.loading.search.data = true;

      this.$store
          .dispatch(PUT_SEARCH, {
            data: this.search
          })
          .then(() => {
            this.$store.dispatch(GET_SERP_QUERY, "?q=" + this.querySerp.query + "&cc=" + this.querySerp.currency + "&lang=" + this.querySerp.localization + "&c=" + this.querySerp.collection);
          });
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
  },
  computed: {
    languagesOptions() {
      return Object.entries(this.app_settings.languages)
          .map(([key, value]) => ({text: value, value: key}));
    },
    currenciesOptions() {
      return Object.entries(this.app_settings.currencies)
          .map(([key, value]) => ({text: value, value: key}));
    },
    orderByOptions() {
      return Object.entries(this.app_settings.orderBy)
          .map(([key, value]) => ({text: value, value: key}));
    },
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.resultSetting.errors,
      message: state => state.message.message,
      loading: state => state.resultSetting.loading,
      autocomplete: state => state.resultSetting.autocomplete,
      catalog: state => state.resultSetting.catalog,
      app_settings: state => state.app_settings,
      layout_columns: state => state.resultSetting.layout_columns,
      search: state => state.resultSetting.search,
      current: state => state.project.current_project,
      collections: state => state.resultSetting.collections
    }),
    ...mapGetters(["currentProject"])
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Result Settings"}]);
  }
};
</script>
